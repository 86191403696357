.login-main-container{
    height: 100vh;
    background-image: url(../../assets/image/fondo_login.png);
    background-repeat: no-repeat;
    background-size: 100% 100%
}

.login-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding: 30px 200px 30px 100px; */
    margin-top: 40px !important;
}

.logo-container{
    text-align: center;
}