.paper-search-table {
    margin-left: 20px;
    /* margin-top: 6px; */
    border-radius: 5px !important;
    width: 350px;
}

.main-container {
    display: flex;
    justify-content: center;
    align-items: center;
}