.paper-search {
    /* margin-top: 4px; */
    border-radius: 30px !important;
}

.main-container {
    display: flex;
    justify-content: center;
    align-items: center;
}
.main-container-text {
    display: block;
    justify-content: center;
    text-align: center;
    cursor: pointer;
}
.main-container-date {
    /* width: 130px; */
    display: block;
    justify-content: center;
    text-align: center;
    cursor: pointer;
}

.text {
    color: #101010;
    font-weight: 400;
    font-size: 12px !important;
    padding-left: 15px;
    padding-right: 15px;
}
.text-departament {
    width: 180px;
    color: #101010;
    font-weight: 400;
    font-size: 12px !important;
    padding-left: 15px;
    padding-right: 15px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.text-option {
    color: #808080;
    font-weight: 400;
    font-size: 12px !important;
    padding-left: 15px;
    padding-right: 15px;
}

.divider {
    border: solid 1px #d1d1d1;
    height: 24px
}