/* MODAL STANDAR */
.modal {
    width: 200px;
    position: absolute;
    right: 0;
}
  
 .modal-header {
    background-color: #fff;
    color: black !important;
 }
  
 .modal-body {
    display: flex;
    flex-direction: column;
    ::placeholder {
      font-size: 18px;
      font-style: italic;
      color: black;
      opacity: 1;
    }
}
.button {
  background-color: #35526f;
  color: #f1f2f3;
  display: inline-block;
  padding: 3px;
  width: 60%;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0 !important;
  cursor: pointer;
}

p {
  display: inline-block;
  margin: 0;
}
  
.modal-footer {
    margin-top: 20px;
    justify-content: space-around;
}

.modal-confirm-footer {
  margin-top: 20px;
  justify-content: center;
  border: 'solid 1px red'
}

.custom-divider {
  margin: 30px 0px 10px 0px;
}

.positionElements {
    display: flex;
    align-items: center;
    columns: 2;
    justify-content: space-between;
    margin-left: 5px;
}