.Banner {
  height: 320px;
  position: relative;
}

.Banner .Media {
  background-color: white;
  height: 100%;
  overflow: hidden;
  position: relative;
  transition: 300ms;
  cursor: pointer;
}

.Banner .Media .MediaCaption {
  text-overflow: ellipsis;
  position: absolute;
  bottom: 0;
  padding: 15px;
  background-color: rgb(0, 12, 174);
  color: white;
  /* opacity: 0.6; */
  width: 100%;
  height: 10%;
  font-size: 36px;
  font-weight: 200;
  transition: 300ms;
  cursor: pointer;
}

.Banner .BannerGrid {
  height: 100%;
  position: relative;
}

.Banner .Content {
  color: white;
  background-color: rgb(18, 28, 76 , .85) ;
  height: 100%;
  position: relative;
  cursor: pointer;
  padding: 30px;
  transition: 300ms;
}

.Banner .Content .Title {
  font-size: 42px;
  font-weight: 500;
}

.Banner .Content .Caption {
  margin-top: 10px;
  font-size: 24px;
}

.Banner .Content .ViewButton {
  margin-top: 40px;
  color: white;
  font-size: 24px;
  border: 3px solid white;
  text-transform: capitalize;
  transition: 200ms;
}